/* This example requires Tailwind CSS v2.0+ */
import { Disclosure } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/outline";
import React from "react";
import { Helmet } from "react-helmet";
const faqs = [
  {
    question: "What are bookkeeping services?",
    answer:
      "Bookkeeping services are the backbone of any successful business. These include accounts payable & accounts receivable management, expense reporting, bank account and credit card reconciliations, sales tax filing, payroll execution and payroll tax filing as well as customized financial reporting. Having an optimized bookkeeping infrastructure will allow a business to focus on their primary workflows and ensure that there is transparency into financial performance. A high end bookkeeping provider will create this infrastructure and manage it on the back-end.",
  },
  {
    question: "Why do companies outsource bookkeeping?",
    answer:
      "Companies outsource bookkeeping in order to assure that they are getting highly competent service without having to train an employee. Outsourcing also reduces costs and can come with added efficiencies as the outsourced firm will have a deep knowledge and understanding of accounting, financial operations and effective financial infrastructure.",
  },
  {
    question: "What is payroll processing services?",
    answer:
      "Payroll processing services ensure financial accuracy as well as ensuring that your employees are paid on time, taxes are submitted to the CRA on time and T4’s are filed and provided to employees on time. A high end payroll provider will also be able to include all payroll details onto your company’s books.",
  },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function Example() {
  return (
    <div className="bg-gray-50">
      <Helmet>
        <script type="application/ld+json">
          {`
            {
              "@context": "https://schema.org",
            "@type": "FAQPage",
            "mainEntity": [{
              "@type": "Question",
            "name": "What are bookkeeping services?",
            "acceptedAnswer": {
              "@type": "Answer",
            "text": "<p>Bookkeeping services are the backbone of any successful business. These include accounts payable & accounts receivable management, expense reporting, bank account and credit card reconciliations, sales tax filing, payroll execution and payroll tax filing as well as customized financial reporting. Having an optimized bookkeeping infrastructure will allow a business to focus on their primary workflows and ensure that there is transparency into financial performance. A high end bookkeeping provider will create this infrastructure and manage it on the back-end.</p>"
                  }
                }, {
            "@type": "Question",
              "name": "Why do companies outsource bookkeeping?",
                "acceptedAnswer": {
              "@type": "Answer",
                "text": "<p>Companies outsource bookkeeping in order to assure that they are getting highly competent service without having to train an employee. Outsourcing also reduces costs and can come with added efficiencies as the outsourced firm will have a deep knowledge and understanding of accounting, financial operations and effective financial infrastructure.<p>"
             }
           },{
            "@type": "Question",
              "name": "What is payroll processing services?",
                "acceptedAnswer": {
              "@type": "Answer",
                "text": "<p>Payroll processing services ensure financial accuracy as well as ensuring that your employees are paid on time, taxes are submitted to the CRA on time and T4’s are filed and provided to employees on time. A high end payroll provider will also be able to include all payroll details onto your company’s books.<p>"
             } 
          }]
              }`}
        </script>
      </Helmet>
      <div className="max-w-7xl mx-auto py-12 px-4 sm:py-16 sm:px-6 lg:px-8">
        <div className="max-w-3xl mx-auto divide-y-2 divide-gray-200">
          <h2 className="text-center text-3xl font-extrabold text-gray-900 sm:text-4xl">
            Frequently Asked Questions
          </h2>
          <dl className="mt-6 space-y-6 divide-y divide-gray-200">
            {faqs.map((faq) => (
              <Disclosure as="div" key={faq.question} className="pt-6">
                {({ open }) => (
                  <>
                    <dt className="text-lg">
                      <Disclosure.Button className="text-left w-full flex justify-between items-start text-gray-400">
                        <span className="font-medium text-gray-900">
                          {faq.question}
                        </span>
                        <span className="ml-6 h-7 flex items-center">
                          <ChevronDownIcon
                            className={classNames(
                              open ? "-rotate-180" : "rotate-0",
                              "h-6 w-6 transform"
                            )}
                            aria-hidden="true"
                          />
                        </span>
                      </Disclosure.Button>
                    </dt>
                    <Disclosure.Panel as="dd" className="mt-2 pr-12">
                      <p className="text-base text-gray-500">{faq.answer}</p>
                    </Disclosure.Panel>
                  </>
                )}
              </Disclosure>
            ))}
          </dl>
        </div>
      </div>
    </div>
  );
}
