import React from "react";
import { Helmet } from 'react-helmet'
export default function Example() {
  return (
    <Helmet htmlAttributes={{
      lang: 'en',
    }}>
      <title>Bookkeeping Toronto | Certus Bookkeeping</title>

      <meta name="description" content="Bookkeeping Toronto services for businesses of all sizes. Contact us to get started today. " />
      <meta name="msvalidate.01" content="B59872A9FC9E11DE85793886DF42383E" />
      <link rel="canonical" href="https://certusbookkeeping.com" />
    </Helmet>
  );
}
