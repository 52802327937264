import React from "react";
import { StaticImage } from "gatsby-plugin-image";
import CTA from "./CTA";
export default function Example() {
  return (
    <div className="relative py-16 bg-white overflow-hidden">
      <div className="hidden lg:block lg:absolute lg:inset-y-0 lg:h-full lg:w-full">
        <div
          className="relative h-full text-lg max-w-prose mx-auto"
          aria-hidden="true"
        >
          <svg
            className="absolute top-12 left-full transform translate-x-32"
            width={404}
            height={384}
            fill="none"
            viewBox="0 0 404 384"
          >
            <defs>
              <pattern
                id="74b3fd99-0a6f-4271-bef2-e80eeafdf357"
                x={0}
                y={0}
                width={20}
                height={20}
                patternUnits="userSpaceOnUse"
              >
                <rect
                  x={0}
                  y={0}
                  width={4}
                  height={4}
                  className="text-gray-200"
                  fill="currentColor"
                />
              </pattern>
            </defs>
            <rect
              width={404}
              height={384}
              fill="url(#74b3fd99-0a6f-4271-bef2-e80eeafdf357)"
            />
          </svg>
          <svg
            className="absolute top-1/2 right-full transform -translate-y-1/2 -translate-x-32"
            width={404}
            height={384}
            fill="none"
            viewBox="0 0 404 384"
          >
            <defs>
              <pattern
                id="f210dbf6-a58d-4871-961e-36d5016a0f49"
                x={0}
                y={0}
                width={20}
                height={20}
                patternUnits="userSpaceOnUse"
              >
                <rect
                  x={0}
                  y={0}
                  width={4}
                  height={4}
                  className="text-gray-200"
                  fill="currentColor"
                />
              </pattern>
            </defs>
            <rect
              width={404}
              height={384}
              fill="url(#f210dbf6-a58d-4871-961e-36d5016a0f49)"
            />
          </svg>
          <svg
            className="absolute bottom-12 left-full transform translate-x-32"
            width={404}
            height={384}
            fill="none"
            viewBox="0 0 404 384"
          >
            <defs>
              <pattern
                id="d3eb07ae-5182-43e6-857d-35c643af9034"
                x={0}
                y={0}
                width={20}
                height={20}
                patternUnits="userSpaceOnUse"
              >
                <rect
                  x={0}
                  y={0}
                  width={4}
                  height={4}
                  className="text-gray-200"
                  fill="currentColor"
                />
              </pattern>
            </defs>
            <rect
              width={404}
              height={384}
              fill="url(#d3eb07ae-5182-43e6-857d-35c643af9034)"
            />
          </svg>
        </div>
      </div>
      <div className="relative px-4 sm:px-6 lg:px-8">
        <div className="text-lg max-w-prose mx-auto">
          <h2>
            <span className="block text-base text-center text-theme font-semibold tracking-wide uppercase">
              Certus
            </span>
            <span className="mt-2 block text-3xl text-center leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
              Bookkeeping Toronto
            </span>
          </h2>
          <p className="mt-6 prose prose-indigo prose-lg text-gray-500 mx-auto">
            Certus Bookkeeping is proud to provide the highest quality
            bookkeeping services in Toronto for both businesses and individuals.
            We are a team that understands that managing your finances is both
            stressful and time-consuming. It can take away from your work-life
            balance and can prevent you from operating your business to the best
            of your ability. At the same time, your finances are not something
            you can trust to just anyone.
          </p>
          <p className="mt-6 prose prose-indigo prose-lg text-gray-500 mx-auto">
            We are the team that you can trust. We have years of experience in
            providing accounting and bookkeeping services across the GTA. Our
            team of professionals will be able to identify your needs and create
            a custom tailored plan to suite them. We provide a level of
            professionalism that will allow you to rest easy knowing that your
            finances are being managed to the highest standards.
          </p>
        </div>
        <div className="text-lg mt-6 prose prose-indigo prose-lg text-gray-500 mx-auto">
          <h2>Bookkeeping For Businesses</h2>
          <p>
            Operating a business is both stressful and time-consuming.
            Especially when you are a small business owner that is managing all
            of the company's finances on your own. Receiving proper bookkeeping
            services from an experienced professional can optimize your business
            and help it reach new heights. We will help you uncover revenue
            streams, prevent cash flow crunches, and ensure you are prepared for
            tax season.
          </p>
          <p>
            Our team of financial experts will start off by evaluating the
            current financial operations and goals of your business. We will
            analyze your workflows and provide you with a detailed process that
            will ensure that your finances are in order. This will save you
            money every year and give you the time to focus on growing your
            business.
          </p>
          <p>
            We proudly offer affordable bookkeeping and accounting services that
            are designed to ensure that your business’s needs and goals are met.
            We will work closely with your business to make sure that you are
            receiving the highest quality service in the GTA.
          </p>
        </div>
        <CTA />
        <div className="text-lg prose prose-indigo text-gray-500 mx-auto">
          <p>
            The Certus Bookkeeping team is more than capable of meeting all of
            your business’s financial requirements. Whether it is payroll
            processing, managing your QuickBooks Online, analyzing your
            workflows or helping you file your tax returns. Certus Bookkeeping
            guarantees that we have the expertise, knowledge, and skill that
            your business requires.
          </p>
          <h2 style={{ marginTop: "0px" }}>Bookkeeping For Individuals</h2>
          <p>
            Certus Bookkeeping also provides premium bookkeeping services for
            individuals. We understand that managing your finances can be
            overwhelming. We pride ourselves on our ability to help our clients
            get their finances in order.
          </p>
          <p>
            We will help you identify your needs and organize your finances via
            a cloud based system that will store all of your information in one
            spot. When it is time to file your taxes you can rest assured that
            your filings will be complete and accurate. All it takes is a simple
            process to help secure your financial future.
          </p>
        </div>
      </div>
    </div>
  );
}
